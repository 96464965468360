import OrderHistoryPage from "../components/accountPage/OrderHistory";
import SEO from "../components/seo";

function OrderHistory() {
  return (
    <>
      <SEO
        title="Order History"
        description="Looking to access your order history at Ana Luisa? Our user-friendly platform allows you to conveniently track and manage your past purchases. Discover your favorite jewelry pieces and stay up-to-date with your Ana Luisa collection. Start exploring your order history today!"
      />
      <OrderHistoryPage />
    </>
  );
}
export default OrderHistory;
